export const getMobileBrowserSource = () => {
  if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
    if (navigator.userAgent.match(/Chrome/i)) {
      return 'iOS_Chrome'
    }
    if (navigator.userAgent.match(/Edge/i)) {
      return 'iOS_Edge'
    }
    if (navigator.userAgent.match(/Firefox/i)) {
      return 'iOS_Firefox'
    }
    if (navigator.userAgent.match(/Safari/i)) {
      return 'iOS_Safari'
    }
  } else if (navigator.userAgent.match(/android/i)) {
    if (navigator.userAgent.match(/Chrome/i)) {
      return 'Android_Chrome'
    }
    if (navigator.userAgent.match(/Edge/i)) {
      return 'Android_Edge'
    }
    if (navigator.userAgent.match(/Firefox/i)) {
      return 'Android_Firefox'
    }
    if (navigator.userAgent.match(/Xiaomi/i)) {
      return 'Android_Xiaomi'
    }
    if (navigator.userAgent.match(/Huawei/i)) {
      return 'Android_Huawei'
    }
    if (navigator.userAgent.match(/Samsung/i)) {
      return 'Android_Samsung'
    }
  }
}