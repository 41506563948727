import React, { useEffect, useState, useMemo } from 'react'
import tw from 'twin.macro'
import { Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useShallow } from 'zustand/react/shallow'
import { indexOf } from 'ramda'
import { useOrientation } from 'react-use'
import { useSearchParams } from 'react-router-dom'

import tap from 'resources/images/tap.png'
import { iamsmart } from 'service/iasmMartService'
import { useLangStore, useIASMStore } from 'store/authAtom'
import { IASMError } from 'utils/staticData'

const Wrap = tw.div`self-center px-[60px] py-[42px] my-[60px] flex flex-col bg-white shadow-lg space-y-6`

export default function InstructionPage() {
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id') + ''
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const tokenInfo = useIASMStore((state) => state.tokenInfo)
    const { lang, redirectUrl } = useLangStore(
        useShallow(({ lang, redirectUrl }) => ({
            lang,
            redirectUrl,
        })),
    )

    const { angle } = useOrientation()
    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])

    const { data: iamSmartGetProfileData, isSuccess } = useQuery({
        queryKey: ['iamSmartGetProfile'],
        queryFn: () =>
            iamsmart.getProfile({
                buinessId: id,
                lang,
            }),
        retry: true,
        enabled: !!id,
    })

    useEffect(() => {
        if (isSuccess && !!iamSmartGetProfileData) {
            if (indexOf(iamSmartGetProfileData.errorCode, IASMError) !== -1) {
                const code = iamSmartGetProfileData.errorCode
                // window.location.href =
                //     (redirectUrl && `${redirectUrl}?lang=${lang} `) || `${process.env.REACT_APP_WEBSITE}?lang=${lang} `
                if (code === 'D40000' || code === 'D40001' || code === 'D50001') {
                    enqueueSnackbar(t('ErrorCode.D50001'), { variant: 'error' })
                }
                if (code === 'D40002' || code === 'D50002') {
                    enqueueSnackbar(t('ErrorCode.D50002'), { variant: 'error' })
                }
                if (code === 'D40003' || code === 'D50003' || code === 'D40004') {
                    enqueueSnackbar(t('ErrorCode.D50003'), { variant: 'error' })
                }
                navigate('/')
            } else {
                navigate('/login/signUp', {
                    state: { ...iamSmartGetProfileData.content, openID: tokenInfo?.openID },
                })
            }
        }
    }, [isSuccess, iamSmartGetProfileData])

    return (
        <Wrap className={`site-grid site-grid--support-medium mx-auto min-h-[297px] ${isHorizontal ? 'isHorizontal' : ''}`}>
            <Typography variant='h5'>{t('NoRecordFound.instructionTitle')}</Typography>

            <div className='py-3 px-5 bg-[#F9F9F9] text-black text-xs leading-4 font-[400]'>
                <div>{t('NoRecordFound.openIntro')}</div>
                <div className='size-12 ml-4 my-1'>
                    <img alt='' src={tap} className='w-full h-full object-contain' />
                </div>
                <div>{t('NoRecordFound.tapFill')}</div>
                <div>{t('NoRecordFound.tapAgree')}</div>
            </div>

            {/* <Button
                variant='contained'
                className='rounded-full normal-case mt-8'
                fullWidth
                disabled={!isGetProfile}
                type='submit'
                onClick={() =>
                    navigate('/login/signUp', {
                        state: iamSmartGetProfileData.content,
                    })
                }
            >
                {t('SignIn.Log in with iAM Smart')}
            </Button> */}
        </Wrap>
    )
}
