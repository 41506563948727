// registration
import { useMemo, useState } from 'react'
import { Button, Typography, useMediaQuery, Box } from '@mui/material'
import tw, { styled } from 'twin.macro'
import { Link as RouteLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import { useOrientation } from 'react-use'

import SignUpCom from './component/SignUpCom'
import { useIASMStore } from 'store/authAtom'
import Iamsmart from 'resources/svg/iamsmart_tag.svg'

const Wrap = tw.div`px-[60px] py-[42px] mt-[60px] flex flex-col bg-white shadow-lg space-y-3`

const FontWrap = tw.div`text-[#FFF] whitespace-pre-line`

const FormWrap = styled.div`
    ${tw`overflow-y-auto space-y-8 mt-8!`}
`
const IasmIcon = tw.img``

export default function SignUp() {
    const { t } = useTranslation()

    const { angle } = useOrientation()
    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.between('md', 'lg')) /// padding 42px
    const matchesLaptopUp = useMediaQuery(theme.breakpoints.up('flatbed')) /// padding 60px
    const matchesUpMD = useMediaQuery(theme.breakpoints.up('md'))
    const matchesDownSM = useMediaQuery(theme.breakpoints.down('sm')) /// padding: 24px 16px;

    const { state: iasmInfo } = useLocation()
    const [submitStatus, setSubmitStatus] = useState(false)

    return (
        <>
            {((isHorizontal && matchesLaptopUp) || (!isHorizontal && matchesUpMD)) && (
                <FontWrap className='font-wrap'>
                    <Typography variant='h2'>{t('Common.bgFont')}</Typography>
                </FontWrap>
            )}

            <Wrap
                className={`form-wrap align-self ${matches ? 'registration' : 'site-grid site-grid--support-medium'} ${
                    isHorizontal && !matchesLaptopUp ? 'isHorizontal' : ''
                }`}
            >
                <Typography variant='h5'>{t('SignUpPage.SignUpDisplay')}</Typography>
                <Typography variant='subtitle2' gutterBottom>
                    {t('SignUpPage.HelloCreateTheAccountNowDisplay')}
                </Typography>

                {!!iasmInfo && (
                    <Box
                        className={[
                            'relative bg-[#E9ECF3] rounded-[12px] py-[12px] pl-[16px] pr-[16px] mt-6 whitespace-pre-line flex items-center',
                        ]}
                    >
                        <IasmIcon src={Iamsmart} />
                        <Typography variant='subtitle2' className='text-[12px] leading-[18px]'>
                            {t('SignUpPage.IASMDisplay')}
                        </Typography>
                    </Box>
                )}

                <FormWrap>
                    <SignUpCom submitStatus={submitStatus} />

                    <Button
                        variant='contained'
                        size='large'
                        className='rounded-full normal-case'
                        fullWidth
                        type='submit'
                        form='signUpForm'
                        onClick={()=> setSubmitStatus(true)}
                    >
                        {t('SignUpPage.ContinueButton')}
                    </Button>
                    <Typography variant='subtitle2'>
                        {t('SignUpPage.AlreadyHaveAaccountDisplay')}{' '}
                        <RouteLink to='/login/signIn' className='text-primaryRed-ktRed underline'>
                            {t('SignUpPage.LoginNowButton')}
                        </RouteLink>
                    </Typography>
                </FormWrap>
            </Wrap>
        </>
    )
}
