import React, { useState, useMemo, useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useShallow } from 'zustand/react/shallow'
import { useOrientation } from 'react-use'

import iamsmartImg from 'resources/images/iamsmart.png'
import redDot from 'resources/images/redDot.png'
import iamsmartLogo from 'resources/images/iamsmartLogo.png'
import { iamsmart } from 'service/iasmMartService'
import { useLangStore, useIASMStore } from 'store/authAtom'
import { getMobileBrowserSource } from "utils/utils";

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#2B7366',
    '&:hover': {
        backgroundColor: '#2B7366',
    },
}))
const Wrap = tw.div`self-center px-[60px] py-[42px] my-[60px] flex flex-col bg-white shadow-lg space-y-6`

export default function ConsentPage() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const [tokenInfo, setTokenInfo] = useState(useIASMStore((state) => state.tokenInfo))
    const { lang, redirectUrl } = useLangStore(
        useShallow(({ lang, redirectUrl }) => ({
            lang,
            redirectUrl,
        })),
    )
    const brokerPage = !!navigator.userAgent.match(/(iPhone|iPod|iPad|android);?/i)

    const { angle } = useOrientation()
    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])

    const initiateRequestMutation = useMutation({
        mutationFn: iamsmart.initiateRequest,
        onSuccess: (v) => {
            if (brokerPage) {
                window.location.href = `${process.env.REACT_APP_ISM_SCHEME}://v2_form-filling?ticketID=${v?.ticketID}`
            }
            navigate('/instructionPage?id=' + v?.businessID)
            window.localStorage.removeItem('tokenInfo')
            // enqueueSnackbar(t('NoRecordFound.Authorize'), { variant: 'success' })
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const handleNewUser = () => {
        let source = 'PC_Browser';
        // if (brokerPage) {
        //     source = getMobileBrowserSource()
        // }
        initiateRequestMutation.mutate({
            accessToken: tokenInfo?.accessToken,
            lang,
            openId: tokenInfo?.openID,
            // source,
        })
    }
    const datas = [t('SignUpPage.EnglishNameHint'), t('SignUpPage.ChineseNameHint'), t('SignUpPage.DateOfBirthDisplay')]
    useEffect(() => {
        if (tokenInfo) {
            window.localStorage.setItem('tokenInfo', JSON.stringify(tokenInfo))
        } else if (!tokenInfo && window.localStorage.getItem('tokenInfo')) {
            setTokenInfo(JSON.parse(window.localStorage.getItem('tokenInfo')))
        } else {
            navigate('/')
        }
    }, [])
    return (
        <Wrap
            className={`site-grid site-grid--support-medium my-[60px] mx-auto min-h-[297px] ${
                isHorizontal ? 'isHorizontal' : ''
            }`}
        >
            <Typography variant='h5'>{t('NoRecordFound.howDoesItWork')}</Typography>
            <div className='my-6 w-[184px] h-[93px] mx-auto'>
                <img alt='iamsmart' src={iamsmartLogo} className='w-full h-full object-contain' />
            </div>
            <div className='py-3 px-5 bg-[#F9F9F9] text-black text-xs leading-4 font-[400]'>
                <div>{t('NoRecordFound.content')}</div>
                <div className='py-4'>{t('NoRecordFound.bomContent')}</div>
                {datas.map((data, index) => {
                    return (
                        <div className='text-sm font-[500] flex gap-[10px]' key={index}>
                            <img alt='' src={redDot} className='object-contain' />
                            <span>{data}</span>
                        </div>
                    )
                })}
                <div className='pt-4'>{t('NoRecordFound.pleaseNote')}</div>
            </div>
            <ColorButton
                variant='contained'
                size='large'
                className='rounded-full normal-case mt-8 mb-3'
                fullWidth
                color='success'
                onClick={handleNewUser}
                startIcon={<img alt='' className='w-5 h-5 object-contain mr-1' src={iamsmartImg} />}
            >
                {t('NoRecordFound.smart')}
            </ColorButton>
        </Wrap>
    )
}
