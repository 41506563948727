import tw from 'twin.macro'
import { useMemo } from 'react'
import { Typography, Button, TextField, useMediaQuery } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { trim } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useOrientation } from 'react-use'
import { useTheme } from '@mui/material/styles'

import { crm } from 'service/crmService'
import { useValidationI18n } from 'utils/validationI18n'
import { useLangStore } from 'store/authAtom'
import { useUpdateErrorMessages } from 'utils/filter'

const Form = tw.form`self-center px-[60px] py-[42px] my-[60px] bg-white shadow-lg flex flex-col space-y-8`

export default function UpdateEmail() {
    const { t } = useTranslation()
    let { state } = useLocation()
    const navigate = useNavigate()
    const { validationSchema } = useValidationI18n()
    const lang = useLangStore((state) => state.lang)

    const { enqueueSnackbar } = useSnackbar()

    const { angle } = useOrientation()
    const theme = useTheme()
    const matchesLaptopUp = useMediaQuery(theme.breakpoints.up('flatbed'))

    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])

    const {
        register,
        handleSubmit,
        getValues,
        reset,
        formState: { errors },
        trigger,
    } = useForm({
        resolver: yupResolver(
            yup.object({
                emailId: validationSchema.emailId,
            }),
        ),
    })

    useUpdateErrorMessages(errors, trigger)

    const checkProfileExistMutation = useMutation({
        mutationFn: crm.checkProfileExist,
        onSuccess: (v) => {
            const formData = getValues()
            navigate('/login/verifyEmail', {
                state: {
                    payload: {
                        languagePreference: state?.lang,
                        nickname: state?.nickName,
                        ...state,
                        emailId: state?.oldEmailID,
                        newEmail: formData?.emailId,
                    },
                    backRoute: '/login/updateEmail',
                },
            })
        },
        onError: (e) => {
            reset()
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const onSend = (formData) => {
        if (state?.oldEmailID === formData?.emailId) {
            enqueueSnackbar(t('UpdateEmail.RepeatEmail'), { variant: 'warning' })
        } else {
            checkProfileExistMutation.mutate({
                emailId: formData?.emailId,
                lang,
            })
        }
    }

    const handleBack = () => {
        navigate('/login/verifyEmail', {
            state: {
                payload: {
                    languagePreference: state?.lang,
                    nickname: state?.nickName,
                    ...state,
                    emailId: state?.oldEmailID,
                    newEmail: null,
                },
                pass: true,
                backRoute: '/login/updateEmail',
            },
        })
    }

    return (
        <Form
            onSubmit={handleSubmit(onSend)}
            autoComplete='off'
            className={`site-grid site-grid--support-medium min-h-[302px] ${
                isHorizontal && !matchesLaptopUp ? 'isHorizontal' : ''
            }`}
        >
            <Typography variant='h5'>{t('UpdateEmail.YourEmailAddressDisplay')}</Typography>

            <TextField
                {...register('emailId', {
                    setValueAs: (v) => trim(v),
                })}
                fullWidth
                label={t('UpdateEmail.EmailAddressDisplay')}
                variant='standard'
                color='secondary'
                placeholder={t('UpdateEmail.EmailGmailComHint')}
                InputLabelProps={{
                    shrink: true,
                }}
                error={!!errors?.emailId}
                helperText={errors?.emailId?.message ?? null}
            />

            <Button variant='contained' size='large' className='rounded-full normal-case mt-6' fullWidth type='submit'>
                {t('UpdateEmail.SendVerificationCodeButton')}
            </Button>
            <Button className='rounded-full normal-case mt-6' variant='outlined' fullWidth onClick={handleBack}>
                {t('Common.Back')}
            </Button>
        </Form>
    )
}
